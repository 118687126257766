import { CSSProperties, Fragment, useCallback, useContext, useState } from 'react';

import { RenderPagePropertiesAndMethods } from 'datocms-plugin-sdk';
import {
  ButtonGroup,
  SidebarLeftArrowIcon,
  SidebarPanel,
  SidebarRightArrowIcon,
  Toolbar,
  ToolbarButton,
  ToolbarStack,
} from 'datocms-react-ui';

import { locales } from '../../../common/constants';
import { ActiveLanguagesPanel } from '../../../components/ActiveLanguagePanels';
import { ActiveModelsPanel } from '../../../components/ActiveModelPanels';
import { Ruler } from '../../../components/Ruler';
import { H1, H2, H3 } from '../../../components/Typography';
import { HoverItemContext } from '../../../context/HoverItemContext';
import s from '../../../global.module.css';
import { useStickyState } from '../../../hooks/useStickyState';
import { useTranslation } from '../../../hooks/useTranslation';
import { ActiveLanguages, ActiveModels } from '../../../types';
import { colorForModel } from '../../../utils/colorForModel';

type PropTypes = {
  ctx: RenderPagePropertiesAndMethods;
};

export default function Translation({ ctx }: PropTypes) {
  const [hoverModelId, setHoverModelId] = useState<string | null>(null);
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [activeLanguages, setActiveLanguages] = useStickyState<ActiveLanguages>(
    'all',
    `contentTranslationPlugin.${ctx.site.id}.${ctx.environment}.activeLanguageIds`
  );

  const [activeModels, setActiveModels] = useStickyState<ActiveModels>(
    'all',
    `contentTranslationPlugin.${ctx.site.id}.${ctx.environment}.activeModelIds`
  );

  const handleToggleSidebar = useCallback(() => {
    setSidebarOpen((x) => !x);
  }, [setSidebarOpen]);

  const { setModelId } = useContext(HoverItemContext);

  const renderUntranslatedList = (
    records: (any & { _status: string; _locales: string[]; id: string })[] | undefined,
    recordTypeId: string,
    languageId: string,
    fieldsToCheckFor: string[]
  ) => {
    // @ts-ignore
    // eslint-disable-next-line array-callback-return
    const result = records?.map((record) => {
      const isAnyFieldUntranslated = (() => {
        let result = false;

        for (const field of fieldsToCheckFor) {
          if (field === '_locales') {
            if (!record[field].includes(languageId)) return true;
          } else {
            const subFieldLocales = record[field].map(({ locale }: { locale: string }) => locale);
            if (!subFieldLocales.includes(languageId)) return true;
          }
        }

        return result;
      })();

      if (isAnyFieldUntranslated) {
        return (
          <button
            key={record.id}
            className={s['item']}
            type="button"
            style={
              {
                '--color-rgb-components': colorForModel(recordTypeId).join(', '),
              } as CSSProperties
            }
            onMouseOver={() => {
              setModelId(recordTypeId);
            }}
            onMouseOut={() => {
              setModelId(null);
            }}
            onClick={() =>
              ctx.navigateTo(`/editor/item_types/${recordTypeId}/items/${record.id}/edit`)
            }
          >
            {record?.title ?? record?.name ?? record?.label ?? record?.id}
          </button>
        );
      }
    });

    if (result?.filter((r) => r !== undefined).length === 0) return undefined;

    return result;
  };

  const { recordTypes, isLoading, isError, errors } = useTranslation();

  if (isLoading) return <H1>Loading...</H1>;

  if (isError) {
    console.error(errors);
    return <H1>There was an error, see logs for more details</H1>;
  }

  return (
    <>
      <HoverItemContext.Provider value={{ modelId: hoverModelId, setModelId: setHoverModelId }}>
        <div className={s['layout']}>
          {isSidebarOpen && (
            <div className={s['layoutSidebar']}>
              <Toolbar>
                <ToolbarStack />
                <ToolbarButton onClick={handleToggleSidebar}>
                  <SidebarLeftArrowIcon />
                </ToolbarButton>
              </Toolbar>
              <SidebarPanel title="Languages" startOpen noPadding>
                <ActiveLanguagesPanel
                  allLanguages={locales}
                  activeLanguages={activeLanguages}
                  onChange={(newActiveLanguages) => {
                    setActiveLanguages(newActiveLanguages);
                  }}
                />
              </SidebarPanel>
              <SidebarPanel title="Models" startOpen noPadding>
                <ActiveModelsPanel
                  allModels={recordTypes}
                  activeModels={activeModels}
                  onChange={(newActiveModels) => {
                    setActiveModels(newActiveModels);
                  }}
                />
              </SidebarPanel>
            </div>
          )}
          <div className={s['layoutMain']}>
            <Toolbar>
              {!isSidebarOpen && (
                <ToolbarButton onClick={handleToggleSidebar}>
                  <SidebarRightArrowIcon />
                </ToolbarButton>
              )}
            </Toolbar>
            <div className={s['layoutList']}>
              <H1>Missing Translations</H1>

              {(activeLanguages === 'all' ? locales : activeLanguages).map((locale) => (
                <Fragment key={locale.id}>
                  <Ruler />
                  <H2>{locale.name}</H2>
                  {(activeModels === 'all'
                    ? recordTypes
                    : recordTypes.filter(({ id }) => activeModels.includes(id))
                  ).map(({ id, name, records, localizedAttributes }) => {
                    const untranslatedList = renderUntranslatedList(
                      records,
                      id,
                      locale.id,
                      localizedAttributes
                    );

                    // @ts-ignore
                    // eslint-disable-next-line array-callback-return
                    if (untranslatedList === undefined) return;

                    return (
                      <Fragment key={name}>
                        <H3>{name}</H3>
                        <ButtonGroup style={{ flexWrap: 'wrap' }}>{untranslatedList}</ButtonGroup>
                      </Fragment>
                    );
                  })}
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </HoverItemContext.Provider>
    </>
  );
}
