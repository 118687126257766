import { CSSProperties, Fragment } from 'react';

import { RenderPagePropertiesAndMethods } from 'datocms-plugin-sdk';

import { Ruler } from '../../../components/Ruler';
import { H1, H2 } from '../../../components/Typography';
import s from '../../../global.module.css';
import {
  FindAllValidationProblemsQuery,
  useFindAllValidationProblemsQuery,
} from '../../../services/dato/graphql/generated';
import { colorForModel } from '../../../utils/colorForModel';
import { getRecordTypeIdFromName } from '../../../utils/recordTypeIdFromName';

type PropTypes = {
  ctx: RenderPagePropertiesAndMethods;
};

export default function Validation({ ctx }: PropTypes) {
  const renderInvalidList = (records: FindAllValidationProblemsQuery | undefined) => {
    if (records === undefined) return null;

    return Object.entries(records).map(([key, value]) => {
      if (Array.isArray(value) && value.length === 0) return null;
      const { recordTypeName, recordTypeId } = getRecordTypeIdFromName(key, true);
      if (!recordTypeId) return null;
      return (
        <Fragment key={key}>
          <Ruler />
          <H2>{recordTypeName}</H2>
          {Array.isArray(value) &&
            value.map((item) => {
              return (
                <button
                  key={item.id}
                  className={`${s['item']} ${s['as-inline-block']}`}
                  type="button"
                  style={
                    {
                      '--color-rgb-components': colorForModel(key).join(', '),
                    } as CSSProperties
                  }
                  onClick={() =>
                    ctx.navigateTo(`/editor/item_types/${recordTypeId}/items/${item.id}/edit`)
                  }
                >
                  {item.id}
                </button>
              );
            })}
        </Fragment>
      );
    });
  };

  const { data, isLoading, isError, error } = useFindAllValidationProblemsQuery();

  if (isLoading) return <H1>Loading...</H1>;

  if (isError) {
    console.error(error);
    return <H1>There was an error, see logs for more details</H1>;
  }

  return (
    <div className={s['layout']}>
      <div className={s['layoutMain']}>
        <div className={s['layoutList']}>
          <H1>Invalid items</H1>
          <div>{data && renderInvalidList(data)}</div>
        </div>
      </div>
    </div>
  );
}
