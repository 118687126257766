import { ReactElement } from 'react';

import { connect, ItemFormSidebarPanel, RenderPagePropertiesAndMethods } from 'datocms-plugin-sdk';
import { Canvas } from 'datocms-react-ui';
import { QueryClient, QueryClientProvider } from 'react-query';

import 'datocms-react-ui/styles.css';

import ConfigScreen from './entrypoints/ConfigScreen';
import { UniversalLinkLangModal } from './entrypoints/modals/UniversalLinkLangModal';
import Publishing from './entrypoints/pages/Publishing';
import Translation from './entrypoints/pages/Translation';
import Validation from './entrypoints/pages/Validation';
import * as AlanSidebarUniversalLink from './entrypoints/sidebar/AlanUniversalLink';
import * as SidebarInAppPreview from './entrypoints/sidebar/InAppPreview';
import * as MindSidebarUniversalLink from './entrypoints/sidebar/MindUniversalLink';
import * as SideBarVideoWithSubtitlesPreview from './entrypoints/sidebar/VideoWithSubtitlesPreview';
import { render } from './utils/render';

const getPage = (pageId: string, ctx: RenderPagePropertiesAndMethods): ReactElement => {
  if (pageId === 'publishing') return <Publishing ctx={ctx} />;
  if (pageId === 'translation') return <Translation ctx={ctx} />;
  if (pageId === 'validation') return <Validation ctx={ctx} />;

  return <></>;
};

const queryClient = new QueryClient();

connect({
  renderConfigScreen(ctx) {
    return render(<ConfigScreen ctx={ctx} />);
  },
  itemFormSidebarPanels(itemType) {
    const panels: ItemFormSidebarPanel[] = [];
    if (MindSidebarUniversalLink.AVAILABLE_ITEM_TYPES.includes(itemType.attributes.name))
      panels.push(MindSidebarUniversalLink.DEFINITION);

    if (AlanSidebarUniversalLink.AVAILABLE_ITEM_TYPES.includes(itemType.attributes.name))
      panels.push(AlanSidebarUniversalLink.DEFINITION);

    if (SidebarInAppPreview.AVAILABLE_ITEM_TYPES.includes(itemType.attributes.name))
      panels.push(SidebarInAppPreview.DEFINITION);

    if (SideBarVideoWithSubtitlesPreview.AVAILABLE_ITEM_TYPES.includes(itemType.attributes.name))
      panels.push(SideBarVideoWithSubtitlesPreview.DEFINITION);

    return panels;
  },
  renderItemFormSidebarPanel(sidebarPaneId, ctx) {
    if (sidebarPaneId === MindSidebarUniversalLink.DEFINITION.id)
      render(<MindSidebarUniversalLink.Component ctx={ctx} />);

    if (sidebarPaneId === AlanSidebarUniversalLink.DEFINITION.id)
      render(<AlanSidebarUniversalLink.Component ctx={ctx} />);

    if (sidebarPaneId === SidebarInAppPreview.DEFINITION.id)
      render(<SidebarInAppPreview.Component ctx={ctx} />);

    if (sidebarPaneId === SideBarVideoWithSubtitlesPreview.DEFINITION.id)
      render(
        <QueryClientProvider client={queryClient}>
          <SideBarVideoWithSubtitlesPreview.Component ctx={ctx} />
        </QueryClientProvider>
      );
  },
  mainNavigationTabs(ctx) {
    return [
      {
        label: 'Publishing',
        icon: 'clock',
        pointsTo: {
          pageId: 'publishing',
        },
      },
      {
        label: 'Translation',
        icon: 'flag',
        pointsTo: {
          pageId: 'translation',
        },
      },
      {
        label: 'Validation',
        icon: 'check',
        pointsTo: {
          pageId: 'validation',
        },
      },
    ];
  },
  renderPage(pageId, ctx) {
    return render(
      <Canvas ctx={ctx}>
        <QueryClientProvider client={queryClient}>{getPage(pageId, ctx)}</QueryClientProvider>
      </Canvas>
    );
  },
  renderModal(modalId, ctx) {
    switch (modalId) {
      case 'universalLinkLangModal':
        return render(<UniversalLinkLangModal ctx={ctx} />);
    }
  },
});
