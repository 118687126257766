import {
  useFindAllAlanMindProfessionsQuery,
  useFindAllAlanMindSpecialtiesQuery,
  useFindAllAlanMindTherapistsQuery,
  useFindAllArticlesQuery,
  useFindAllBreakthroughDefinitionsQuery,
  useFindAllBreakthroughsQuery,
  useFindAllBreathingExercisesQuery,
  useFindAllDailyJournalMoodQuestionsQuery,
  useFindAllJourneyAudioStepsQuery,
  useFindAllJourneyQuestionStepsQuery,
  useFindAllJourneySessionDescriptionsQuery,
  useFindAllJourneySessionNamesQuery,
  useFindAllJourneySessionNextsQuery,
  useFindAllJourneySessionRecapsQuery,
  useFindAllJourneysQuery,
  useFindAllJourneyTransitionStepsQuery,
  useFindAllMeditationExercisesQuery,
  useFindAllOnboardingCarouselPagesQuery,
  useFindAllOnboardingCarouselScreensQuery,
  useFindAllOnboardingCategoriesQuery,
  useFindAllOnboardingCategoryScoreGridsQuery,
  useFindAllOnboardingMobileFlowsQuery,
  useFindAllOnboardingPaywallCategoriesQuery,
  useFindAllOnboardingPaywallCommentsQuery,
  useFindAllOnboardingPaywallScreensQuery,
  useFindAllOnboardingQuestionScreensQuery,
  useFindAllOnboardingSliderQuestionScreensQuery,
  useFindAllOnboardingTransitionScreensQuery,
  useFindAllQuotesQuery,
  useFindAllVideoExpertsQuery,
  useFindAllVideoLessonsQuery,
  useFindAllVideoModulesQuery,
  useFindAllVideoTagsQuery,
} from '../services/dato/graphql/generated';

export const useTranslation = () => {
  const {
    data: articlesData,
    isLoading: isArticlesLoading,
    isError: isArticlesError,
    error: articlesError,
  } = useFindAllArticlesQuery({});

  const {
    data: breakthroughsData,
    isLoading: isBreakthroughsLoading,
    isError: isBreakthroughsError,
    error: breakthroughsError,
  } = useFindAllBreakthroughsQuery({});

  const {
    data: videoModulesData,
    isLoading: isVideoModulesLoading,
    isError: isVideoModulesError,
    error: videoModulesError,
  } = useFindAllVideoModulesQuery({});

  const {
    data: alanMindProfessionsData,
    isLoading: isAlanMindProfessionsLoading,
    isError: isAlanMindProfessionsError,
    error: alanMindProfessionsError,
  } = useFindAllAlanMindProfessionsQuery({});

  const {
    data: alanMindSpecialtiesData,
    isLoading: isAlanMindSpecialtiesLoading,
    isError: isAlanMindSpecialtiesError,
    error: alanMindSpecialtiesError,
  } = useFindAllAlanMindSpecialtiesQuery({});

  const {
    data: alanMindTherapistsData,
    isLoading: isAlanMindTherapistsLoading,
    isError: isAlanMindTherapistsError,
    error: alanMindTherapistsError,
  } = useFindAllAlanMindTherapistsQuery({});

  const {
    data: breakthroughDefinitionsData,
    isLoading: isBreakthroughDefinitionsLoading,
    isError: isBreakthroughDefinitionsError,
    error: breakthroughDefinitionsError,
  } = useFindAllBreakthroughDefinitionsQuery({});

  const {
    data: dailyJournalMoodQuestionsData,
    isLoading: isDailyJournalMoodQuestionsLoading,
    isError: isDailyJournalMoodQuestionsError,
    error: dailyJournalMoodQuestionsError,
  } = useFindAllDailyJournalMoodQuestionsQuery({});

  const {
    data: journeyAudioStepsData,
    isLoading: isJourneyAudioStepsLoading,
    isError: isJourneyAudioStepsError,
    error: journeyAudioStepsError,
  } = useFindAllJourneyAudioStepsQuery({});

  const {
    data: journeyQuestionStepsData,
    isLoading: isJourneyQuestionStepsLoading,
    isError: isJourneyQuestionStepsError,
    error: journeyQuestionStepsError,
  } = useFindAllJourneyQuestionStepsQuery({});

  const {
    data: journeysData,
    isLoading: isJourneysLoading,
    isError: isJourneysError,
    error: journeysError,
  } = useFindAllJourneysQuery({});

  const {
    data: journeySessionNamesData,
    isLoading: isJourneySessionNamesLoading,
    isError: isJourneySessionNamesError,
    error: journeySessionNamesError,
  } = useFindAllJourneySessionNamesQuery({});

  const {
    data: journeySessionNextsData,
    isLoading: isJourneySessionNextsLoading,
    isError: isJourneySessionNextsError,
    error: journeySessionNextsError,
  } = useFindAllJourneySessionNextsQuery({});

  const {
    data: journeySessionRecapsData,
    isLoading: isJourneySessionRecapsLoading,
    isError: isJourneySessionRecapsError,
    error: journeySessionRecapsError,
  } = useFindAllJourneySessionRecapsQuery({});

  const {
    data: journeyTransitionStepsData,
    isLoading: isJourneyTransitionStepsLoading,
    isError: isJourneyTransitionStepsError,
    error: journeyTransitionStepsError,
  } = useFindAllJourneyTransitionStepsQuery({});

  const {
    data: onboardingCarouselPagesData,
    isLoading: isOnboardingCarouselPagesLoading,
    isError: isOnboardingCarouselPagesError,
    error: onboardingCarouselPagesError,
  } = useFindAllOnboardingCarouselPagesQuery({});

  const {
    data: onboardingCarouselScreensData,
    isLoading: isOnboardingCarouselScreensLoading,
    isError: isOnboardingCarouselScreensError,
    error: onboardingCarouselScreensError,
  } = useFindAllOnboardingCarouselScreensQuery({});

  const {
    data: onboardingCategoriesData,
    isLoading: isOnboardingCategoriesLoading,
    isError: isOnboardingCategoriesError,
    error: onboardingCategoriesError,
  } = useFindAllOnboardingCategoriesQuery({});

  const {
    data: onboardingCategoryScoreGridsData,
    isLoading: isOnboardingCategoryScoreGridsLoading,
    isError: isOnboardingCategoryScoreGridsError,
    error: onboardingCategoryScoreGridsError,
  } = useFindAllOnboardingCategoryScoreGridsQuery({});

  const {
    data: onboardingMobileFlowsData,
    isLoading: isOnboardingMobileFlowsLoading,
    isError: isOnboardingMobileFlowsError,
    error: onboardingMobileFlowsError,
  } = useFindAllOnboardingMobileFlowsQuery({});

  const {
    data: onboardingSliderQuestionScreensData,
    isLoading: isOnboardingSliderQuestionScreensLoading,
    isError: isOnboardingSliderQuestionScreensError,
    error: onboardingSliderQuestionScreensError,
  } = useFindAllOnboardingSliderQuestionScreensQuery({});

  const {
    data: onboardingQuestionScreensData,
    isLoading: isOnboardingQuestionScreensLoading,
    isError: isOnboardingQuestionScreensError,
    error: onboardingQuestionScreensError,
  } = useFindAllOnboardingQuestionScreensQuery({});

  const {
    data: onboardingTransitionScreensData,
    isLoading: isOnboardingTransitionScreensLoading,
    isError: isOnboardingTransitionScreensError,
    error: onboardingTransitionScreensError,
  } = useFindAllOnboardingTransitionScreensQuery({});

  const {
    data: quotesData,
    isLoading: isQuotesLoading,
    isError: isQuotesError,
    error: quotesError,
  } = useFindAllQuotesQuery({});

  const {
    data: videoExpertsData,
    isLoading: isVideoExpertsLoading,
    isError: isVideoExpertsError,
    error: videoExpertsError,
  } = useFindAllVideoExpertsQuery({});

  const {
    data: videoLessonsData,
    isLoading: isVideoLessonsLoading,
    isError: isVideoLessonsError,
    error: videoLessonsError,
  } = useFindAllVideoLessonsQuery({});

  const {
    data: videoTagsData,
    isLoading: isVideoTagsLoading,
    isError: isVideoTagsError,
    error: videoTagsError,
  } = useFindAllVideoTagsQuery({});

  const {
    data: journeySessionDescriptionsData,
    isLoading: isJourneySessionDescriptionsLoading,
    isError: isJourneySessionDescriptionsError,
    error: journeySessionDescriptionsError,
  } = useFindAllJourneySessionDescriptionsQuery({});

  const {
    data: breathingExercisesData,
    isLoading: isBreathingExercisesLoading,
    isError: isBreathingExercisesError,
    error: breathingExercisesError,
  } = useFindAllBreathingExercisesQuery({});

  const {
    data: meditationExercisesData,
    isLoading: isMeditationExercisesLoading,
    isError: isMeditationExercisesError,
    error: meditationExercisesError,
  } = useFindAllMeditationExercisesQuery({});

  const {
    data: onboardingPaywallCommentsData,
    isLoading: isOnboardingPaywallCommentsLoading,
    isError: isOnboardingPaywallCommentsError,
    error: onboardingPaywallCommentsError,
  } = useFindAllOnboardingPaywallCommentsQuery({});

  const {
    data: onboardingPaywallScreensData,
    isLoading: isOnboardingPaywallScreensLoading,
    isError: isOnboardingPaywallScreensError,
    error: onboardingPaywallScreensError,
  } = useFindAllOnboardingPaywallScreensQuery({});

  const {
    data: onboardingPaywallCategoriesData,
    isLoading: isOnboardingPaywallCategoriesLoading,
    isError: isOnboardingPaywallCategoriesError,
    error: onboardingPaywallCategoriesError,
  } = useFindAllOnboardingPaywallCategoriesQuery({});

  return {
    isLoading:
      isArticlesLoading ||
      isBreakthroughsLoading ||
      isVideoModulesLoading ||
      isAlanMindProfessionsLoading ||
      isAlanMindSpecialtiesLoading ||
      isAlanMindTherapistsLoading ||
      isBreakthroughDefinitionsLoading ||
      isDailyJournalMoodQuestionsLoading ||
      isJourneyAudioStepsLoading ||
      isJourneyQuestionStepsLoading ||
      isJourneysLoading ||
      isJourneySessionNamesLoading ||
      isJourneySessionNextsLoading ||
      isJourneySessionRecapsLoading ||
      isJourneyTransitionStepsLoading ||
      isOnboardingCarouselPagesLoading ||
      isOnboardingCarouselScreensLoading ||
      isOnboardingCategoriesLoading ||
      isOnboardingCategoryScoreGridsLoading ||
      isOnboardingMobileFlowsLoading ||
      isOnboardingSliderQuestionScreensLoading ||
      isOnboardingQuestionScreensLoading ||
      isOnboardingTransitionScreensLoading ||
      isQuotesLoading ||
      isVideoExpertsLoading ||
      isVideoLessonsLoading ||
      isVideoTagsLoading ||
      isJourneySessionDescriptionsLoading ||
      isBreathingExercisesLoading ||
      isMeditationExercisesLoading ||
      isOnboardingPaywallCommentsLoading ||
      isOnboardingPaywallScreensLoading ||
      isOnboardingPaywallCategoriesLoading,
    isError:
      isArticlesError ||
      isBreakthroughsError ||
      isVideoModulesError ||
      isAlanMindProfessionsError ||
      isAlanMindSpecialtiesError ||
      isAlanMindTherapistsError ||
      isBreakthroughDefinitionsError ||
      isDailyJournalMoodQuestionsError ||
      isJourneyAudioStepsError ||
      isJourneyQuestionStepsError ||
      isJourneysError ||
      isJourneySessionNamesError ||
      isJourneySessionNextsError ||
      isJourneySessionRecapsError ||
      isJourneyTransitionStepsError ||
      isOnboardingCarouselPagesError ||
      isOnboardingCarouselScreensError ||
      isOnboardingCategoriesError ||
      isOnboardingCategoryScoreGridsError ||
      isOnboardingMobileFlowsError ||
      isOnboardingSliderQuestionScreensError ||
      isOnboardingQuestionScreensError ||
      isOnboardingTransitionScreensError ||
      isQuotesError ||
      isVideoExpertsError ||
      isVideoLessonsError ||
      isVideoTagsError ||
      isJourneySessionDescriptionsError ||
      isBreathingExercisesError ||
      isMeditationExercisesError ||
      isOnboardingPaywallCommentsError ||
      isOnboardingPaywallScreensError ||
      isOnboardingPaywallCategoriesError,
    errors: [
      articlesError,
      breakthroughsError,
      videoModulesError,
      alanMindProfessionsError,
      alanMindSpecialtiesError,
      alanMindTherapistsError,
      breakthroughDefinitionsError,
      dailyJournalMoodQuestionsError,
      journeyAudioStepsError,
      journeyQuestionStepsError,
      journeysError,
      journeySessionNamesError,
      journeySessionNextsError,
      journeySessionRecapsError,
      journeyTransitionStepsError,
      onboardingCarouselPagesError,
      onboardingCarouselScreensError,
      onboardingCategoriesError,
      onboardingCategoryScoreGridsError,
      onboardingMobileFlowsError,
      onboardingSliderQuestionScreensError,
      onboardingQuestionScreensError,
      onboardingTransitionScreensError,
      quotesError,
      videoExpertsError,
      videoLessonsError,
      videoTagsError,
      journeySessionDescriptionsError,
      breathingExercisesError,
      meditationExercisesError,
      onboardingPaywallCommentsError,
      onboardingPaywallScreensError || onboardingPaywallCategoriesError,
    ],
    recordTypes: [
      {
        name: 'Alan Mind Professions',
        id: '1286275',
        records: alanMindProfessionsData?.allAlanMindProfessions,
        localizedAttributes: ['_allContentLocales'],
      },
      {
        name: 'Alan Mind Specialties',
        id: '1286274',
        records: alanMindSpecialtiesData?.allAlanMindSpecialties,
        localizedAttributes: ['_allNameLocales'],
      },
      {
        name: 'Alan Mind Therapists',
        id: '1286273',
        records: alanMindTherapistsData?.allAlanMindTherapists,
        localizedAttributes: ['_allDescriptionLocales'],
      },
      {
        name: 'Articles',
        id: '1286270',
        records: articlesData?.allArticles,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Breakthrough Definitions',
        id: '1286283',
        records: breakthroughDefinitionsData?.allBreakthroughDefinitions,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Breakthroughs',
        id: '1286282',
        records: breakthroughsData?.allBreakthroughs,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Daily Journal Mood Questions',
        id: '1286281',
        records: dailyJournalMoodQuestionsData?.allDailyJournalMoodQuestions,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Journey Audio Steps',
        id: '1286269',
        records: journeyAudioStepsData?.allJourneyAudioSteps,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Journey Question Steps',
        id: '1286267',
        records: journeyQuestionStepsData?.allJourneyQuestionSteps,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Journeys',
        id: '1286271',
        records: journeysData?.allJourneys,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Journey Session Descriptions',
        id: '1286277',
        records: journeySessionDescriptionsData?.allJourneySessionDescriptions,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Journey Session Names',
        id: '1286278',
        records: journeySessionNamesData?.allJourneySessionNames,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Journey Session Nexts',
        id: '1286295',
        records: journeySessionNextsData?.allJourneySessionNexts,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Journey Session Recaps',
        id: '1286272',
        records: journeySessionRecapsData?.allJourneySessionRecaps,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Journey Transition Steps',
        id: '1286268',
        records: journeyTransitionStepsData?.allJourneyTransitionSteps,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Onboarding Carousel Pages',
        id: '2023009',
        records: onboardingCarouselPagesData?.allOnboardingCarouselPages,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Onboarding Carousel Screens',
        id: '2023003',
        records: onboardingCarouselScreensData?.allOnboardingCarouselScreens,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Onboarding Categories',
        id: '1286299',
        records: onboardingCategoriesData?.allOnboardingCategories,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Onboarding Categories Score Grids',
        id: '1320162',
        records: onboardingCategoryScoreGridsData?.allOnboardingCategoryScoreGrids,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Onboarding Mobile Flows',
        id: '1286297',
        records: onboardingMobileFlowsData?.allOnboardingMobileFlows,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Onboarding Slider Question Screens',
        id: '2023137',
        records: onboardingSliderQuestionScreensData?.allOnboardingSliderQuestionScreens,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Onboarding Question Screens',
        id: '1286284',
        records: onboardingQuestionScreensData?.allOnboardingQuestionScreens,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Onboarding Transition Screens',
        id: '1286287',
        records: onboardingTransitionScreensData?.allOnboardingTransitionScreens,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Quotes',
        id: '1286288',
        records: quotesData?.allQuotes,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Video Experts',
        id: '1316340',
        records: videoExpertsData?.allVideoExperts,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Video Lessons',
        id: '1316341',
        records: videoLessonsData?.allVideoLessons,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Video Modules',
        id: '1316342',
        records: videoModulesData?.allVideoModules,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Video Tags',
        id: '1339434',
        records: videoTagsData?.allVideoTags,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Breathing Exercises',
        id: '1943378',
        records: breathingExercisesData?.allBreathingExercises,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Meditation Exercises',
        id: '1996236',
        records: meditationExercisesData?.allMeditationExercises,
        localizedAttributes: ['_locales'],
      },
      {
        name: 'Onboarding Paywall Comments',
        id: '2025819',
        records: onboardingPaywallCommentsData?.allOnboardingPaywallComments,
        localizedAttributes: ['_allAuthorLocales'],
      },
      {
        name: 'Onboarding Paywall Screens',
        id: '2025817',
        records: onboardingPaywallScreensData?.allOnboardingPaywallScreens,
        localizedAttributes: [
          '_allCategoriesLocales',
          '_allCtaCopyLocales',
          '_allPlanExercisesLocales',
          '_allPlanTitleLocales',
          '_allSubtitleLocales',
          '_allTitleLocales',
        ],
      },
      {
        name: 'Onboarding Paywall Categories',
        id: '2025818',
        records: onboardingPaywallCategoriesData?.allOnboardingPaywallCategories,
        localizedAttributes: ['_allNameLocales'],
      },
    ] as {
      name: string;
      id: string;
      records: any[] | undefined;
      localizedAttributes: string[];
    }[],
  };
};
