import { CSSProperties } from 'react';

import { RenderPagePropertiesAndMethods } from 'datocms-plugin-sdk';
import { ButtonGroup } from 'datocms-react-ui';

import { Ruler } from '../../../components/Ruler';
import { H1, H2 } from '../../../components/Typography';
import s from '../../../global.module.css';
import { usePublishing } from '../../../hooks/usePublishing';
import { colorForModel } from '../../../utils/colorForModel';

type PropTypes = {
  ctx: RenderPagePropertiesAndMethods;
};

export default function Publishing({ ctx }: PropTypes) {
  const { recordTypes, isError, errors, isLoading } = usePublishing();

  const renderUnpublishedList = (
    records: (any & { _status: string })[] | undefined,
    recordTypeId: string
  ) => {
    const result = records?.map((record) => {
      if (record._status === 'published') return undefined;
      return (
        <button
          key={record.id}
          className={s['item']}
          style={
            {
              '--color-rgb-components': colorForModel(recordTypeId).join(', '),
            } as CSSProperties
          }
          type="button"
          onClick={() =>
            ctx.navigateTo(`/editor/item_types/${recordTypeId}/items/${record.id}/edit`)
          }
        >
          {record.id}
        </button>
      );
    });

    if (result?.filter((r) => r !== undefined).length === 0) return undefined;

    return <ButtonGroup style={{ flexWrap: 'wrap' }}>{result}</ButtonGroup>;
  };

  if (isLoading) return <H1>Loading...</H1>;

  if (isError) {
    console.error(errors);
    return <H1>There was an error, see logs for more details</H1>;
  }

  return (
    <div className={s['layout']}>
      <div className={s['layoutMain']}>
        <div className={s['layoutList']}>
          <H1>All records which need to be published</H1>
          {recordTypes.map((recordType) => (
            <div>
              <Ruler />
              <H2>{recordType.name}</H2>
              {renderUnpublishedList(recordType.records, recordType.id) ??
                `All ${recordType.name} records have been published!`}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
