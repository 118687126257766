import { useCallback } from 'react';

import classNames from 'classnames';
import { Button } from 'datocms-react-ui';

import { ActiveLanguages, Language } from '../../types';
import s from './styles.module.css';

type ActiveLanguagePanelProps = {
  allLanguages: Language[];
  activeLanguages: ActiveLanguages;
  onChange: (newValue: ActiveLanguages) => void;
};

export function ActiveLanguagesPanel({
  allLanguages,
  activeLanguages,
  onChange,
}: ActiveLanguagePanelProps) {
  const handleDeselectAll = useCallback(() => {
    onChange([]);
  }, [onChange]);

  const handleSelectAll = useCallback(() => {
    onChange('all');
  }, [onChange]);

  const allSortedLanguages = allLanguages.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div className={s.root}>
      <div className={s.options}>
        {activeLanguages !== 'all' && (
          <Button type="button" fullWidth buttonSize="xxs" onClick={handleSelectAll}>
            Select all
          </Button>
        )}
        {(activeLanguages === 'all' || activeLanguages.length > 0) && (
          <Button type="button" fullWidth buttonSize="xxs" onClick={handleDeselectAll}>
            Deselect all
          </Button>
        )}
      </div>
      {allSortedLanguages.map((language) => {
        const active =
          activeLanguages === 'all' || activeLanguages.some(({ id }) => id === language.id);

        return (
          <div
            key={language.id}
            className={classNames(s.language)}
            id={`languageId-${language.id}`}
          >
            <label className={classNames(s.toggler, { [s.togglerActive]: active })}>
              <input
                type="checkbox"
                checked={active}
                onChange={() => {
                  if (activeLanguages === 'all') {
                    onChange(allSortedLanguages.filter(({ id }) => id !== language.id));
                  } else if (active) {
                    onChange(activeLanguages.filter(({ id }) => id !== language.id));
                  } else {
                    const newValue = [...activeLanguages, language];
                    if (
                      allSortedLanguages
                        .map((m) => m.id)
                        .sort()
                        .join(',') === newValue.sort().join(',')
                    ) {
                      onChange('all');
                    } else {
                      onChange(newValue);
                    }
                  }
                }}
              />{' '}
              {language.name}
            </label>
          </div>
        );
      })}
    </div>
  );
}
