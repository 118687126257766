import { useQuery, UseQueryOptions } from 'react-query';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };

function fetcher<TData, TVariables>(query: string, variables?: TVariables) {
  return async (): Promise<TData> => {
    const res = await fetch('https://graphql.datocms.com/preview', {
      method: 'POST',
      ...{ headers: { Authorization: 'ad9b595aedd8ea69d180f812a0c3a9' } },
      body: JSON.stringify({ query, variables }),
    });

    const json = await res.json();

    if (json.errors) {
      const { message } = json.errors[0];

      throw new Error(message);
    }

    return json.data;
  };
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BooleanType: any;
  CustomData: any;
  DateTime: any;
  FloatType: any;
  IntType: any;
  ItemId: any;
  JsonField: any;
  MetaTagAttributes: any;
  UploadId: any;
};

export enum ItemStatus {
  Draft = 'draft',
  Published = 'published',
  Updated = 'updated',
}

export enum SiteLocale {
  En = 'en',
  Es = 'es',
  Fr = 'fr',
  FrBe = 'fr_BE',
  Nl = 'nl',
  Uk = 'uk',
}

export type FindAllAlanMindProfessionsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllAlanMindProfessionsQuery = {
  __typename?: 'Query';
  allAlanMindProfessions: Array<{
    __typename?: 'AlanMindProfessionRecord';
    id: any;
    _status: ItemStatus;
    _allContentLocales?: Array<{
      __typename?: 'StringMultiLocaleField';
      locale?: SiteLocale | null;
    }> | null;
  }>;
};

export type FindAllAlanMindSpecialtiesQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllAlanMindSpecialtiesQuery = {
  __typename?: 'Query';
  allAlanMindSpecialties: Array<{
    __typename?: 'AlanMindSpecialtyRecord';
    id: any;
    _status: ItemStatus;
    _allNameLocales?: Array<{
      __typename?: 'StringMultiLocaleField';
      locale?: SiteLocale | null;
    }> | null;
  }>;
};

export type FindAllAlanMindTherapistsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllAlanMindTherapistsQuery = {
  __typename?: 'Query';
  allAlanMindTherapists: Array<{
    __typename?: 'AlanMindTherapistRecord';
    id: any;
    _status: ItemStatus;
    _allDescriptionLocales?: Array<{
      __typename?: 'AlanMindTherapistModelDescriptionFieldMultiLocaleField';
      locale?: SiteLocale | null;
    }> | null;
  }>;
};

export type FindAllArticlesQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllArticlesQuery = {
  __typename?: 'Query';
  allArticles: Array<{
    __typename?: 'ArticleRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllBreakthroughDefinitionsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllBreakthroughDefinitionsQuery = {
  __typename?: 'Query';
  allBreakthroughDefinitions: Array<{
    __typename?: 'BreakthroughDefinitionRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllBreakthroughsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllBreakthroughsQuery = {
  __typename?: 'Query';
  allBreakthroughs: Array<{
    __typename?: 'BreakthroughRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllBreathingExercisesQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllBreathingExercisesQuery = {
  __typename?: 'Query';
  allBreathingExercises: Array<{
    __typename?: 'BreathingExerciseRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllDailyJournalMoodQuestionsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllDailyJournalMoodQuestionsQuery = {
  __typename?: 'Query';
  allDailyJournalMoodQuestions: Array<{
    __typename?: 'DailyJournalMoodQuestionRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllJourneyAudioStepsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllJourneyAudioStepsQuery = {
  __typename?: 'Query';
  allJourneyAudioSteps: Array<{
    __typename?: 'JourneyAudioStepRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllJourneyQuestionStepsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllJourneyQuestionStepsQuery = {
  __typename?: 'Query';
  allJourneyQuestionSteps: Array<{
    __typename?: 'JourneyQuestionStepRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllJourneySessionDescriptionsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllJourneySessionDescriptionsQuery = {
  __typename?: 'Query';
  allJourneySessionDescriptions: Array<{
    __typename?: 'JourneySessionDescriptionRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllJourneySessionNamesQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllJourneySessionNamesQuery = {
  __typename?: 'Query';
  allJourneySessionNames: Array<{
    __typename?: 'JourneySessionNameRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllJourneySessionNextsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllJourneySessionNextsQuery = {
  __typename?: 'Query';
  allJourneySessionNexts: Array<{
    __typename?: 'JourneySessionNextRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllJourneySessionRecapsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllJourneySessionRecapsQuery = {
  __typename?: 'Query';
  allJourneySessionRecaps: Array<{
    __typename?: 'JourneySessionRecapRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllJourneyTransitionStepsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllJourneyTransitionStepsQuery = {
  __typename?: 'Query';
  allJourneyTransitionSteps: Array<{
    __typename?: 'JourneyTransitionStepRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllJourneysQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllJourneysQuery = {
  __typename?: 'Query';
  allJourneys: Array<{
    __typename?: 'JourneyRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllMeditationExercisesQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllMeditationExercisesQuery = {
  __typename?: 'Query';
  allMeditationExercises: Array<{
    __typename?: 'MeditationExerciseRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllOnboardingCarouselPagesQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllOnboardingCarouselPagesQuery = {
  __typename?: 'Query';
  allOnboardingCarouselPages: Array<{
    __typename?: 'OnboardingCarouselPageRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllOnboardingCarouselScreensQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllOnboardingCarouselScreensQuery = {
  __typename?: 'Query';
  allOnboardingCarouselScreens: Array<{
    __typename?: 'OnboardingCarouselScreenRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllOnboardingCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllOnboardingCategoriesQuery = {
  __typename?: 'Query';
  allOnboardingCategories: Array<{
    __typename?: 'OnboardingCategoryRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllOnboardingCategoryScoreGridsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllOnboardingCategoryScoreGridsQuery = {
  __typename?: 'Query';
  allOnboardingCategoryScoreGrids: Array<{
    __typename?: 'OnboardingCategoryScoreGridRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllOnboardingMobileFlowsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllOnboardingMobileFlowsQuery = {
  __typename?: 'Query';
  allOnboardingMobileFlows: Array<{
    __typename?: 'OnboardingMobileFlowRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllOnboardingPaywallCategoriesQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllOnboardingPaywallCategoriesQuery = {
  __typename?: 'Query';
  allOnboardingPaywallCategories: Array<{
    __typename?: 'OnboardingPaywallCategoryRecord';
    _allNameLocales?: Array<{
      __typename?: 'StringMultiLocaleField';
      locale?: SiteLocale | null;
    }> | null;
  }>;
};

export type FindAllOnboardingPaywallCommentsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllOnboardingPaywallCommentsQuery = {
  __typename?: 'Query';
  allOnboardingPaywallComments: Array<{
    __typename?: 'OnboardingPaywallCommentRecord';
    id: any;
    _status: ItemStatus;
    _allAuthorLocales?: Array<{
      __typename?: 'StringMultiLocaleField';
      locale?: SiteLocale | null;
    }> | null;
  }>;
};

export type FindAllOnboardingPaywallScreensQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllOnboardingPaywallScreensQuery = {
  __typename?: 'Query';
  allOnboardingPaywallScreens: Array<{
    __typename?: 'OnboardingPaywallScreenRecord';
    _status: ItemStatus;
    _allCategoriesLocales?: Array<{
      __typename?: 'OnboardingPaywallCategoryRecordListListNonNullMultiLocaleField';
      locale?: SiteLocale | null;
    }> | null;
    _allCtaCopyLocales?: Array<{
      __typename?: 'StringMultiLocaleField';
      locale?: SiteLocale | null;
    }> | null;
    _allPlanExercisesLocales?: Array<{
      __typename?: 'OnboardingPaywallCategoryRecordListListNonNullMultiLocaleField';
      locale?: SiteLocale | null;
    }> | null;
    _allPlanTitleLocales?: Array<{
      __typename?: 'StringMultiLocaleField';
      locale?: SiteLocale | null;
    }> | null;
    _allSubtitleLocales?: Array<{
      __typename?: 'StringMultiLocaleField';
      locale?: SiteLocale | null;
    }> | null;
    _allTitleLocales?: Array<{
      __typename?: 'StringMultiLocaleField';
      locale?: SiteLocale | null;
    }> | null;
  }>;
};

export type FindAllOnboardingQuestionScreensQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllOnboardingQuestionScreensQuery = {
  __typename?: 'Query';
  allOnboardingQuestionScreens: Array<{
    __typename?: 'OnboardingQuestionScreenRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllOnboardingSliderQuestionScreensQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllOnboardingSliderQuestionScreensQuery = {
  __typename?: 'Query';
  allOnboardingSliderQuestionScreens: Array<{
    __typename?: 'OnboardingSliderQuestionScreenRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllOnboardingTransitionScreensQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllOnboardingTransitionScreensQuery = {
  __typename?: 'Query';
  allOnboardingTransitionScreens: Array<{
    __typename?: 'OnboardingTransitionScreenRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllQuotesQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllQuotesQuery = {
  __typename?: 'Query';
  allQuotes: Array<{
    __typename?: 'QuoteRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllValidationProblemsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllValidationProblemsQuery = {
  __typename?: 'Query';
  allArticleAuthors: Array<{ __typename?: 'ArticleAuthorRecord'; id: any }>;
  allAlanMindLanguages: Array<{ __typename?: 'AlanMindLanguageRecord'; id: any }>;
  allAlanMindProfessions: Array<{ __typename?: 'AlanMindProfessionRecord'; id: any }>;
  allAlanMindSpecialties: Array<{ __typename?: 'AlanMindSpecialtyRecord'; id: any }>;
  allAlanMindTherapists: Array<{ __typename?: 'AlanMindTherapistRecord'; id: any }>;
  allArticles: Array<{ __typename?: 'ArticleRecord'; id: any }>;
  allBreakthroughDefinitions: Array<{ __typename?: 'BreakthroughDefinitionRecord'; id: any }>;
  allBreakthroughs: Array<{ __typename?: 'BreakthroughRecord'; id: any }>;
  allBreathingExercises: Array<{ __typename?: 'BreathingExerciseRecord'; id: any }>;
  allDailyJournalMoodQuestions: Array<{ __typename?: 'DailyJournalMoodQuestionRecord'; id: any }>;
  allDailyJournalQuestionsLists: Array<{ __typename?: 'DailyJournalQuestionsListRecord'; id: any }>;
  allJourneyAudioSteps: Array<{ __typename?: 'JourneyAudioStepRecord'; id: any }>;
  allJourneyBreathingSteps: Array<{ __typename?: 'JourneyBreathingStepRecord'; id: any }>;
  allJourneyQuestionSteps: Array<{ __typename?: 'JourneyQuestionStepRecord'; id: any }>;
  allJourneySessionDescriptions: Array<{ __typename?: 'JourneySessionDescriptionRecord'; id: any }>;
  allJourneySessionNames: Array<{ __typename?: 'JourneySessionNameRecord'; id: any }>;
  allJourneySessionNexts: Array<{ __typename?: 'JourneySessionNextRecord'; id: any }>;
  allJourneySessionRecaps: Array<{ __typename?: 'JourneySessionRecapRecord'; id: any }>;
  allJourneyTransitionSteps: Array<{ __typename?: 'JourneyTransitionStepRecord'; id: any }>;
  allJourneys: Array<{ __typename?: 'JourneyRecord'; id: any }>;
  allOnboardingCarouselPages: Array<{ __typename?: 'OnboardingCarouselPageRecord'; id: any }>;
  allOnboardingCarouselScreens: Array<{ __typename?: 'OnboardingCarouselScreenRecord'; id: any }>;
  allOnboardingCategories: Array<{ __typename?: 'OnboardingCategoryRecord'; id: any }>;
  allOnboardingCategoryScoreGrids: Array<{
    __typename?: 'OnboardingCategoryScoreGridRecord';
    id: any;
  }>;
  allOnboardingLandingScreens: Array<{ __typename?: 'OnboardingLandingScreenRecord'; id: any }>;
  allOnboardingMobileFlows: Array<{ __typename?: 'OnboardingMobileFlowRecord'; id: any }>;
  allOnboardingSliderQuestionScreens: Array<{
    __typename?: 'OnboardingSliderQuestionScreenRecord';
    id: any;
  }>;
  allOnboardingQuestionScreens: Array<{ __typename?: 'OnboardingQuestionScreenRecord'; id: any }>;
  allOnboardingTransitionScreens: Array<{
    __typename?: 'OnboardingTransitionScreenRecord';
    id: any;
  }>;
  allQuotes: Array<{ __typename?: 'QuoteRecord'; id: any }>;
  allVideoExperts: Array<{ __typename?: 'VideoExpertRecord'; id: any }>;
  allVideoLessons: Array<{ __typename?: 'VideoLessonRecord'; id: any }>;
  allVideoModules: Array<{ __typename?: 'VideoModuleRecord'; id: any }>;
  allVideoTags: Array<{ __typename?: 'VideoTagRecord'; id: any }>;
};

export type FindAllVideoExpertsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllVideoExpertsQuery = {
  __typename?: 'Query';
  allVideoExperts: Array<{
    __typename?: 'VideoExpertRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllVideoLessonsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllVideoLessonsQuery = {
  __typename?: 'Query';
  allVideoLessons: Array<{
    __typename?: 'VideoLessonRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllVideoModulesQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllVideoModulesQuery = {
  __typename?: 'Query';
  allVideoModules: Array<{
    __typename?: 'VideoModuleRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindAllVideoTagsQueryVariables = Exact<{ [key: string]: never }>;

export type FindAllVideoTagsQuery = {
  __typename?: 'Query';
  allVideoTags: Array<{
    __typename?: 'VideoTagRecord';
    id: any;
    _locales: Array<SiteLocale>;
    _status: ItemStatus;
  }>;
};

export type FindVideoLessonQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ItemId']>;
  locale?: InputMaybe<SiteLocale>;
}>;

export type FindVideoLessonQuery = {
  __typename?: 'Query';
  videoLesson?: {
    __typename?: 'VideoLessonRecord';
    subtitles?: { __typename?: 'FileField'; url: string } | null;
    video?: {
      __typename?: 'FileField';
      video?: { __typename?: 'UploadVideoField'; mp4Url?: string | null } | null;
    } | null;
  } | null;
};

export const FindAllAlanMindProfessionsDocument = `
    query findAllAlanMindProfessions {
  allAlanMindProfessions {
    id
    _allContentLocales {
      locale
    }
    _status
  }
}
    `;
export const useFindAllAlanMindProfessionsQuery = <
  TData = FindAllAlanMindProfessionsQuery,
  TError = unknown
>(
  variables?: FindAllAlanMindProfessionsQueryVariables,
  options?: UseQueryOptions<FindAllAlanMindProfessionsQuery, TError, TData>
) =>
  useQuery<FindAllAlanMindProfessionsQuery, TError, TData>(
    variables === undefined
      ? ['findAllAlanMindProfessions']
      : ['findAllAlanMindProfessions', variables],
    fetcher<FindAllAlanMindProfessionsQuery, FindAllAlanMindProfessionsQueryVariables>(
      FindAllAlanMindProfessionsDocument,
      variables
    ),
    options
  );
export const FindAllAlanMindSpecialtiesDocument = `
    query findAllAlanMindSpecialties {
  allAlanMindSpecialties {
    id
    _allNameLocales {
      locale
    }
    _status
  }
}
    `;
export const useFindAllAlanMindSpecialtiesQuery = <
  TData = FindAllAlanMindSpecialtiesQuery,
  TError = unknown
>(
  variables?: FindAllAlanMindSpecialtiesQueryVariables,
  options?: UseQueryOptions<FindAllAlanMindSpecialtiesQuery, TError, TData>
) =>
  useQuery<FindAllAlanMindSpecialtiesQuery, TError, TData>(
    variables === undefined
      ? ['findAllAlanMindSpecialties']
      : ['findAllAlanMindSpecialties', variables],
    fetcher<FindAllAlanMindSpecialtiesQuery, FindAllAlanMindSpecialtiesQueryVariables>(
      FindAllAlanMindSpecialtiesDocument,
      variables
    ),
    options
  );
export const FindAllAlanMindTherapistsDocument = `
    query findAllAlanMindTherapists {
  allAlanMindTherapists {
    id
    _allDescriptionLocales {
      locale
    }
    _status
  }
}
    `;
export const useFindAllAlanMindTherapistsQuery = <
  TData = FindAllAlanMindTherapistsQuery,
  TError = unknown
>(
  variables?: FindAllAlanMindTherapistsQueryVariables,
  options?: UseQueryOptions<FindAllAlanMindTherapistsQuery, TError, TData>
) =>
  useQuery<FindAllAlanMindTherapistsQuery, TError, TData>(
    variables === undefined
      ? ['findAllAlanMindTherapists']
      : ['findAllAlanMindTherapists', variables],
    fetcher<FindAllAlanMindTherapistsQuery, FindAllAlanMindTherapistsQueryVariables>(
      FindAllAlanMindTherapistsDocument,
      variables
    ),
    options
  );
export const FindAllArticlesDocument = `
    query findAllArticles {
  allArticles {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllArticlesQuery = <TData = FindAllArticlesQuery, TError = unknown>(
  variables?: FindAllArticlesQueryVariables,
  options?: UseQueryOptions<FindAllArticlesQuery, TError, TData>
) =>
  useQuery<FindAllArticlesQuery, TError, TData>(
    variables === undefined ? ['findAllArticles'] : ['findAllArticles', variables],
    fetcher<FindAllArticlesQuery, FindAllArticlesQueryVariables>(
      FindAllArticlesDocument,
      variables
    ),
    options
  );
export const FindAllBreakthroughDefinitionsDocument = `
    query findAllBreakthroughDefinitions {
  allBreakthroughDefinitions {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllBreakthroughDefinitionsQuery = <
  TData = FindAllBreakthroughDefinitionsQuery,
  TError = unknown
>(
  variables?: FindAllBreakthroughDefinitionsQueryVariables,
  options?: UseQueryOptions<FindAllBreakthroughDefinitionsQuery, TError, TData>
) =>
  useQuery<FindAllBreakthroughDefinitionsQuery, TError, TData>(
    variables === undefined
      ? ['findAllBreakthroughDefinitions']
      : ['findAllBreakthroughDefinitions', variables],
    fetcher<FindAllBreakthroughDefinitionsQuery, FindAllBreakthroughDefinitionsQueryVariables>(
      FindAllBreakthroughDefinitionsDocument,
      variables
    ),
    options
  );
export const FindAllBreakthroughsDocument = `
    query findAllBreakthroughs {
  allBreakthroughs {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllBreakthroughsQuery = <TData = FindAllBreakthroughsQuery, TError = unknown>(
  variables?: FindAllBreakthroughsQueryVariables,
  options?: UseQueryOptions<FindAllBreakthroughsQuery, TError, TData>
) =>
  useQuery<FindAllBreakthroughsQuery, TError, TData>(
    variables === undefined ? ['findAllBreakthroughs'] : ['findAllBreakthroughs', variables],
    fetcher<FindAllBreakthroughsQuery, FindAllBreakthroughsQueryVariables>(
      FindAllBreakthroughsDocument,
      variables
    ),
    options
  );
export const FindAllBreathingExercisesDocument = `
    query findAllBreathingExercises {
  allBreathingExercises {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllBreathingExercisesQuery = <
  TData = FindAllBreathingExercisesQuery,
  TError = unknown
>(
  variables?: FindAllBreathingExercisesQueryVariables,
  options?: UseQueryOptions<FindAllBreathingExercisesQuery, TError, TData>
) =>
  useQuery<FindAllBreathingExercisesQuery, TError, TData>(
    variables === undefined
      ? ['findAllBreathingExercises']
      : ['findAllBreathingExercises', variables],
    fetcher<FindAllBreathingExercisesQuery, FindAllBreathingExercisesQueryVariables>(
      FindAllBreathingExercisesDocument,
      variables
    ),
    options
  );
export const FindAllDailyJournalMoodQuestionsDocument = `
    query findAllDailyJournalMoodQuestions {
  allDailyJournalMoodQuestions {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllDailyJournalMoodQuestionsQuery = <
  TData = FindAllDailyJournalMoodQuestionsQuery,
  TError = unknown
>(
  variables?: FindAllDailyJournalMoodQuestionsQueryVariables,
  options?: UseQueryOptions<FindAllDailyJournalMoodQuestionsQuery, TError, TData>
) =>
  useQuery<FindAllDailyJournalMoodQuestionsQuery, TError, TData>(
    variables === undefined
      ? ['findAllDailyJournalMoodQuestions']
      : ['findAllDailyJournalMoodQuestions', variables],
    fetcher<FindAllDailyJournalMoodQuestionsQuery, FindAllDailyJournalMoodQuestionsQueryVariables>(
      FindAllDailyJournalMoodQuestionsDocument,
      variables
    ),
    options
  );
export const FindAllJourneyAudioStepsDocument = `
    query findAllJourneyAudioSteps {
  allJourneyAudioSteps {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllJourneyAudioStepsQuery = <
  TData = FindAllJourneyAudioStepsQuery,
  TError = unknown
>(
  variables?: FindAllJourneyAudioStepsQueryVariables,
  options?: UseQueryOptions<FindAllJourneyAudioStepsQuery, TError, TData>
) =>
  useQuery<FindAllJourneyAudioStepsQuery, TError, TData>(
    variables === undefined
      ? ['findAllJourneyAudioSteps']
      : ['findAllJourneyAudioSteps', variables],
    fetcher<FindAllJourneyAudioStepsQuery, FindAllJourneyAudioStepsQueryVariables>(
      FindAllJourneyAudioStepsDocument,
      variables
    ),
    options
  );
export const FindAllJourneyQuestionStepsDocument = `
    query findAllJourneyQuestionSteps {
  allJourneyQuestionSteps {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllJourneyQuestionStepsQuery = <
  TData = FindAllJourneyQuestionStepsQuery,
  TError = unknown
>(
  variables?: FindAllJourneyQuestionStepsQueryVariables,
  options?: UseQueryOptions<FindAllJourneyQuestionStepsQuery, TError, TData>
) =>
  useQuery<FindAllJourneyQuestionStepsQuery, TError, TData>(
    variables === undefined
      ? ['findAllJourneyQuestionSteps']
      : ['findAllJourneyQuestionSteps', variables],
    fetcher<FindAllJourneyQuestionStepsQuery, FindAllJourneyQuestionStepsQueryVariables>(
      FindAllJourneyQuestionStepsDocument,
      variables
    ),
    options
  );
export const FindAllJourneySessionDescriptionsDocument = `
    query findAllJourneySessionDescriptions {
  allJourneySessionDescriptions {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllJourneySessionDescriptionsQuery = <
  TData = FindAllJourneySessionDescriptionsQuery,
  TError = unknown
>(
  variables?: FindAllJourneySessionDescriptionsQueryVariables,
  options?: UseQueryOptions<FindAllJourneySessionDescriptionsQuery, TError, TData>
) =>
  useQuery<FindAllJourneySessionDescriptionsQuery, TError, TData>(
    variables === undefined
      ? ['findAllJourneySessionDescriptions']
      : ['findAllJourneySessionDescriptions', variables],
    fetcher<
      FindAllJourneySessionDescriptionsQuery,
      FindAllJourneySessionDescriptionsQueryVariables
    >(FindAllJourneySessionDescriptionsDocument, variables),
    options
  );
export const FindAllJourneySessionNamesDocument = `
    query findAllJourneySessionNames {
  allJourneySessionNames {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllJourneySessionNamesQuery = <
  TData = FindAllJourneySessionNamesQuery,
  TError = unknown
>(
  variables?: FindAllJourneySessionNamesQueryVariables,
  options?: UseQueryOptions<FindAllJourneySessionNamesQuery, TError, TData>
) =>
  useQuery<FindAllJourneySessionNamesQuery, TError, TData>(
    variables === undefined
      ? ['findAllJourneySessionNames']
      : ['findAllJourneySessionNames', variables],
    fetcher<FindAllJourneySessionNamesQuery, FindAllJourneySessionNamesQueryVariables>(
      FindAllJourneySessionNamesDocument,
      variables
    ),
    options
  );
export const FindAllJourneySessionNextsDocument = `
    query findAllJourneySessionNexts {
  allJourneySessionNexts {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllJourneySessionNextsQuery = <
  TData = FindAllJourneySessionNextsQuery,
  TError = unknown
>(
  variables?: FindAllJourneySessionNextsQueryVariables,
  options?: UseQueryOptions<FindAllJourneySessionNextsQuery, TError, TData>
) =>
  useQuery<FindAllJourneySessionNextsQuery, TError, TData>(
    variables === undefined
      ? ['findAllJourneySessionNexts']
      : ['findAllJourneySessionNexts', variables],
    fetcher<FindAllJourneySessionNextsQuery, FindAllJourneySessionNextsQueryVariables>(
      FindAllJourneySessionNextsDocument,
      variables
    ),
    options
  );
export const FindAllJourneySessionRecapsDocument = `
    query findAllJourneySessionRecaps {
  allJourneySessionRecaps {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllJourneySessionRecapsQuery = <
  TData = FindAllJourneySessionRecapsQuery,
  TError = unknown
>(
  variables?: FindAllJourneySessionRecapsQueryVariables,
  options?: UseQueryOptions<FindAllJourneySessionRecapsQuery, TError, TData>
) =>
  useQuery<FindAllJourneySessionRecapsQuery, TError, TData>(
    variables === undefined
      ? ['findAllJourneySessionRecaps']
      : ['findAllJourneySessionRecaps', variables],
    fetcher<FindAllJourneySessionRecapsQuery, FindAllJourneySessionRecapsQueryVariables>(
      FindAllJourneySessionRecapsDocument,
      variables
    ),
    options
  );
export const FindAllJourneyTransitionStepsDocument = `
    query findAllJourneyTransitionSteps {
  allJourneyTransitionSteps {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllJourneyTransitionStepsQuery = <
  TData = FindAllJourneyTransitionStepsQuery,
  TError = unknown
>(
  variables?: FindAllJourneyTransitionStepsQueryVariables,
  options?: UseQueryOptions<FindAllJourneyTransitionStepsQuery, TError, TData>
) =>
  useQuery<FindAllJourneyTransitionStepsQuery, TError, TData>(
    variables === undefined
      ? ['findAllJourneyTransitionSteps']
      : ['findAllJourneyTransitionSteps', variables],
    fetcher<FindAllJourneyTransitionStepsQuery, FindAllJourneyTransitionStepsQueryVariables>(
      FindAllJourneyTransitionStepsDocument,
      variables
    ),
    options
  );
export const FindAllJourneysDocument = `
    query findAllJourneys {
  allJourneys {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllJourneysQuery = <TData = FindAllJourneysQuery, TError = unknown>(
  variables?: FindAllJourneysQueryVariables,
  options?: UseQueryOptions<FindAllJourneysQuery, TError, TData>
) =>
  useQuery<FindAllJourneysQuery, TError, TData>(
    variables === undefined ? ['findAllJourneys'] : ['findAllJourneys', variables],
    fetcher<FindAllJourneysQuery, FindAllJourneysQueryVariables>(
      FindAllJourneysDocument,
      variables
    ),
    options
  );
export const FindAllMeditationExercisesDocument = `
    query findAllMeditationExercises {
  allMeditationExercises {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllMeditationExercisesQuery = <
  TData = FindAllMeditationExercisesQuery,
  TError = unknown
>(
  variables?: FindAllMeditationExercisesQueryVariables,
  options?: UseQueryOptions<FindAllMeditationExercisesQuery, TError, TData>
) =>
  useQuery<FindAllMeditationExercisesQuery, TError, TData>(
    variables === undefined
      ? ['findAllMeditationExercises']
      : ['findAllMeditationExercises', variables],
    fetcher<FindAllMeditationExercisesQuery, FindAllMeditationExercisesQueryVariables>(
      FindAllMeditationExercisesDocument,
      variables
    ),
    options
  );
export const FindAllOnboardingCarouselPagesDocument = `
    query findAllOnboardingCarouselPages {
  allOnboardingCarouselPages {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllOnboardingCarouselPagesQuery = <
  TData = FindAllOnboardingCarouselPagesQuery,
  TError = unknown
>(
  variables?: FindAllOnboardingCarouselPagesQueryVariables,
  options?: UseQueryOptions<FindAllOnboardingCarouselPagesQuery, TError, TData>
) =>
  useQuery<FindAllOnboardingCarouselPagesQuery, TError, TData>(
    variables === undefined
      ? ['findAllOnboardingCarouselPages']
      : ['findAllOnboardingCarouselPages', variables],
    fetcher<FindAllOnboardingCarouselPagesQuery, FindAllOnboardingCarouselPagesQueryVariables>(
      FindAllOnboardingCarouselPagesDocument,
      variables
    ),
    options
  );
export const FindAllOnboardingCarouselScreensDocument = `
    query findAllOnboardingCarouselScreens {
  allOnboardingCarouselScreens {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllOnboardingCarouselScreensQuery = <
  TData = FindAllOnboardingCarouselScreensQuery,
  TError = unknown
>(
  variables?: FindAllOnboardingCarouselScreensQueryVariables,
  options?: UseQueryOptions<FindAllOnboardingCarouselScreensQuery, TError, TData>
) =>
  useQuery<FindAllOnboardingCarouselScreensQuery, TError, TData>(
    variables === undefined
      ? ['findAllOnboardingCarouselScreens']
      : ['findAllOnboardingCarouselScreens', variables],
    fetcher<FindAllOnboardingCarouselScreensQuery, FindAllOnboardingCarouselScreensQueryVariables>(
      FindAllOnboardingCarouselScreensDocument,
      variables
    ),
    options
  );
export const FindAllOnboardingCategoriesDocument = `
    query findAllOnboardingCategories {
  allOnboardingCategories {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllOnboardingCategoriesQuery = <
  TData = FindAllOnboardingCategoriesQuery,
  TError = unknown
>(
  variables?: FindAllOnboardingCategoriesQueryVariables,
  options?: UseQueryOptions<FindAllOnboardingCategoriesQuery, TError, TData>
) =>
  useQuery<FindAllOnboardingCategoriesQuery, TError, TData>(
    variables === undefined
      ? ['findAllOnboardingCategories']
      : ['findAllOnboardingCategories', variables],
    fetcher<FindAllOnboardingCategoriesQuery, FindAllOnboardingCategoriesQueryVariables>(
      FindAllOnboardingCategoriesDocument,
      variables
    ),
    options
  );
export const FindAllOnboardingCategoryScoreGridsDocument = `
    query findAllOnboardingCategoryScoreGrids {
  allOnboardingCategoryScoreGrids {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllOnboardingCategoryScoreGridsQuery = <
  TData = FindAllOnboardingCategoryScoreGridsQuery,
  TError = unknown
>(
  variables?: FindAllOnboardingCategoryScoreGridsQueryVariables,
  options?: UseQueryOptions<FindAllOnboardingCategoryScoreGridsQuery, TError, TData>
) =>
  useQuery<FindAllOnboardingCategoryScoreGridsQuery, TError, TData>(
    variables === undefined
      ? ['findAllOnboardingCategoryScoreGrids']
      : ['findAllOnboardingCategoryScoreGrids', variables],
    fetcher<
      FindAllOnboardingCategoryScoreGridsQuery,
      FindAllOnboardingCategoryScoreGridsQueryVariables
    >(FindAllOnboardingCategoryScoreGridsDocument, variables),
    options
  );
export const FindAllOnboardingMobileFlowsDocument = `
    query findAllOnboardingMobileFlows {
  allOnboardingMobileFlows {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllOnboardingMobileFlowsQuery = <
  TData = FindAllOnboardingMobileFlowsQuery,
  TError = unknown
>(
  variables?: FindAllOnboardingMobileFlowsQueryVariables,
  options?: UseQueryOptions<FindAllOnboardingMobileFlowsQuery, TError, TData>
) =>
  useQuery<FindAllOnboardingMobileFlowsQuery, TError, TData>(
    variables === undefined
      ? ['findAllOnboardingMobileFlows']
      : ['findAllOnboardingMobileFlows', variables],
    fetcher<FindAllOnboardingMobileFlowsQuery, FindAllOnboardingMobileFlowsQueryVariables>(
      FindAllOnboardingMobileFlowsDocument,
      variables
    ),
    options
  );
export const FindAllOnboardingPaywallCategoriesDocument = `
    query findAllOnboardingPaywallCategories {
  allOnboardingPaywallCategories {
    _allNameLocales {
      locale
    }
  }
}
    `;
export const useFindAllOnboardingPaywallCategoriesQuery = <
  TData = FindAllOnboardingPaywallCategoriesQuery,
  TError = unknown
>(
  variables?: FindAllOnboardingPaywallCategoriesQueryVariables,
  options?: UseQueryOptions<FindAllOnboardingPaywallCategoriesQuery, TError, TData>
) =>
  useQuery<FindAllOnboardingPaywallCategoriesQuery, TError, TData>(
    variables === undefined
      ? ['findAllOnboardingPaywallCategories']
      : ['findAllOnboardingPaywallCategories', variables],
    fetcher<
      FindAllOnboardingPaywallCategoriesQuery,
      FindAllOnboardingPaywallCategoriesQueryVariables
    >(FindAllOnboardingPaywallCategoriesDocument, variables),
    options
  );
export const FindAllOnboardingPaywallCommentsDocument = `
    query findAllOnboardingPaywallComments {
  allOnboardingPaywallComments {
    _allAuthorLocales {
      locale
    }
    id
    _status
  }
}
    `;
export const useFindAllOnboardingPaywallCommentsQuery = <
  TData = FindAllOnboardingPaywallCommentsQuery,
  TError = unknown
>(
  variables?: FindAllOnboardingPaywallCommentsQueryVariables,
  options?: UseQueryOptions<FindAllOnboardingPaywallCommentsQuery, TError, TData>
) =>
  useQuery<FindAllOnboardingPaywallCommentsQuery, TError, TData>(
    variables === undefined
      ? ['findAllOnboardingPaywallComments']
      : ['findAllOnboardingPaywallComments', variables],
    fetcher<FindAllOnboardingPaywallCommentsQuery, FindAllOnboardingPaywallCommentsQueryVariables>(
      FindAllOnboardingPaywallCommentsDocument,
      variables
    ),
    options
  );
export const FindAllOnboardingPaywallScreensDocument = `
    query findAllOnboardingPaywallScreens {
  allOnboardingPaywallScreens {
    _allCategoriesLocales {
      locale
    }
    _allCtaCopyLocales {
      locale
    }
    _allPlanExercisesLocales {
      locale
    }
    _allPlanTitleLocales {
      locale
    }
    _allSubtitleLocales {
      locale
    }
    _allTitleLocales {
      locale
    }
    _status
  }
}
    `;
export const useFindAllOnboardingPaywallScreensQuery = <
  TData = FindAllOnboardingPaywallScreensQuery,
  TError = unknown
>(
  variables?: FindAllOnboardingPaywallScreensQueryVariables,
  options?: UseQueryOptions<FindAllOnboardingPaywallScreensQuery, TError, TData>
) =>
  useQuery<FindAllOnboardingPaywallScreensQuery, TError, TData>(
    variables === undefined
      ? ['findAllOnboardingPaywallScreens']
      : ['findAllOnboardingPaywallScreens', variables],
    fetcher<FindAllOnboardingPaywallScreensQuery, FindAllOnboardingPaywallScreensQueryVariables>(
      FindAllOnboardingPaywallScreensDocument,
      variables
    ),
    options
  );
export const FindAllOnboardingQuestionScreensDocument = `
    query findAllOnboardingQuestionScreens {
  allOnboardingQuestionScreens {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllOnboardingQuestionScreensQuery = <
  TData = FindAllOnboardingQuestionScreensQuery,
  TError = unknown
>(
  variables?: FindAllOnboardingQuestionScreensQueryVariables,
  options?: UseQueryOptions<FindAllOnboardingQuestionScreensQuery, TError, TData>
) =>
  useQuery<FindAllOnboardingQuestionScreensQuery, TError, TData>(
    variables === undefined
      ? ['findAllOnboardingQuestionScreens']
      : ['findAllOnboardingQuestionScreens', variables],
    fetcher<FindAllOnboardingQuestionScreensQuery, FindAllOnboardingQuestionScreensQueryVariables>(
      FindAllOnboardingQuestionScreensDocument,
      variables
    ),
    options
  );
export const FindAllOnboardingSliderQuestionScreensDocument = `
    query findAllOnboardingSliderQuestionScreens {
  allOnboardingSliderQuestionScreens {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllOnboardingSliderQuestionScreensQuery = <
  TData = FindAllOnboardingSliderQuestionScreensQuery,
  TError = unknown
>(
  variables?: FindAllOnboardingSliderQuestionScreensQueryVariables,
  options?: UseQueryOptions<FindAllOnboardingSliderQuestionScreensQuery, TError, TData>
) =>
  useQuery<FindAllOnboardingSliderQuestionScreensQuery, TError, TData>(
    variables === undefined
      ? ['findAllOnboardingSliderQuestionScreens']
      : ['findAllOnboardingSliderQuestionScreens', variables],
    fetcher<
      FindAllOnboardingSliderQuestionScreensQuery,
      FindAllOnboardingSliderQuestionScreensQueryVariables
    >(FindAllOnboardingSliderQuestionScreensDocument, variables),
    options
  );
export const FindAllOnboardingTransitionScreensDocument = `
    query findAllOnboardingTransitionScreens {
  allOnboardingTransitionScreens {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllOnboardingTransitionScreensQuery = <
  TData = FindAllOnboardingTransitionScreensQuery,
  TError = unknown
>(
  variables?: FindAllOnboardingTransitionScreensQueryVariables,
  options?: UseQueryOptions<FindAllOnboardingTransitionScreensQuery, TError, TData>
) =>
  useQuery<FindAllOnboardingTransitionScreensQuery, TError, TData>(
    variables === undefined
      ? ['findAllOnboardingTransitionScreens']
      : ['findAllOnboardingTransitionScreens', variables],
    fetcher<
      FindAllOnboardingTransitionScreensQuery,
      FindAllOnboardingTransitionScreensQueryVariables
    >(FindAllOnboardingTransitionScreensDocument, variables),
    options
  );
export const FindAllQuotesDocument = `
    query findAllQuotes {
  allQuotes {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllQuotesQuery = <TData = FindAllQuotesQuery, TError = unknown>(
  variables?: FindAllQuotesQueryVariables,
  options?: UseQueryOptions<FindAllQuotesQuery, TError, TData>
) =>
  useQuery<FindAllQuotesQuery, TError, TData>(
    variables === undefined ? ['findAllQuotes'] : ['findAllQuotes', variables],
    fetcher<FindAllQuotesQuery, FindAllQuotesQueryVariables>(FindAllQuotesDocument, variables),
    options
  );
export const FindAllValidationProblemsDocument = `
    query findAllValidationProblems {
  allArticleAuthors(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allAlanMindLanguages(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allAlanMindProfessions(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allAlanMindSpecialties(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allAlanMindTherapists(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allArticles(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allBreakthroughDefinitions(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allBreakthroughs(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allBreathingExercises(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allDailyJournalMoodQuestions(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allDailyJournalQuestionsLists(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allJourneyAudioSteps(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allJourneyBreathingSteps(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allJourneyQuestionSteps(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allJourneySessionDescriptions(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allJourneySessionNames(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allJourneySessionNexts(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allJourneySessionRecaps(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allJourneyTransitionSteps(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allJourneys(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allOnboardingCarouselPages(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allOnboardingCarouselScreens(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allOnboardingCategories(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allOnboardingCategoryScoreGrids(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allOnboardingLandingScreens(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allOnboardingMobileFlows(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allOnboardingSliderQuestionScreens(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allOnboardingQuestionScreens(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allOnboardingTransitionScreens(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allQuotes(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allVideoExperts(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allVideoLessons(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allVideoModules(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
  allVideoTags(first: 100, filter: {_isValid: {eq: false}}) {
    id
  }
}
    `;
export const useFindAllValidationProblemsQuery = <
  TData = FindAllValidationProblemsQuery,
  TError = unknown
>(
  variables?: FindAllValidationProblemsQueryVariables,
  options?: UseQueryOptions<FindAllValidationProblemsQuery, TError, TData>
) =>
  useQuery<FindAllValidationProblemsQuery, TError, TData>(
    variables === undefined
      ? ['findAllValidationProblems']
      : ['findAllValidationProblems', variables],
    fetcher<FindAllValidationProblemsQuery, FindAllValidationProblemsQueryVariables>(
      FindAllValidationProblemsDocument,
      variables
    ),
    options
  );
export const FindAllVideoExpertsDocument = `
    query findAllVideoExperts {
  allVideoExperts {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllVideoExpertsQuery = <TData = FindAllVideoExpertsQuery, TError = unknown>(
  variables?: FindAllVideoExpertsQueryVariables,
  options?: UseQueryOptions<FindAllVideoExpertsQuery, TError, TData>
) =>
  useQuery<FindAllVideoExpertsQuery, TError, TData>(
    variables === undefined ? ['findAllVideoExperts'] : ['findAllVideoExperts', variables],
    fetcher<FindAllVideoExpertsQuery, FindAllVideoExpertsQueryVariables>(
      FindAllVideoExpertsDocument,
      variables
    ),
    options
  );
export const FindAllVideoLessonsDocument = `
    query findAllVideoLessons {
  allVideoLessons {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllVideoLessonsQuery = <TData = FindAllVideoLessonsQuery, TError = unknown>(
  variables?: FindAllVideoLessonsQueryVariables,
  options?: UseQueryOptions<FindAllVideoLessonsQuery, TError, TData>
) =>
  useQuery<FindAllVideoLessonsQuery, TError, TData>(
    variables === undefined ? ['findAllVideoLessons'] : ['findAllVideoLessons', variables],
    fetcher<FindAllVideoLessonsQuery, FindAllVideoLessonsQueryVariables>(
      FindAllVideoLessonsDocument,
      variables
    ),
    options
  );
export const FindAllVideoModulesDocument = `
    query findAllVideoModules {
  allVideoModules {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllVideoModulesQuery = <TData = FindAllVideoModulesQuery, TError = unknown>(
  variables?: FindAllVideoModulesQueryVariables,
  options?: UseQueryOptions<FindAllVideoModulesQuery, TError, TData>
) =>
  useQuery<FindAllVideoModulesQuery, TError, TData>(
    variables === undefined ? ['findAllVideoModules'] : ['findAllVideoModules', variables],
    fetcher<FindAllVideoModulesQuery, FindAllVideoModulesQueryVariables>(
      FindAllVideoModulesDocument,
      variables
    ),
    options
  );
export const FindAllVideoTagsDocument = `
    query findAllVideoTags {
  allVideoTags {
    id
    _locales
    _status
  }
}
    `;
export const useFindAllVideoTagsQuery = <TData = FindAllVideoTagsQuery, TError = unknown>(
  variables?: FindAllVideoTagsQueryVariables,
  options?: UseQueryOptions<FindAllVideoTagsQuery, TError, TData>
) =>
  useQuery<FindAllVideoTagsQuery, TError, TData>(
    variables === undefined ? ['findAllVideoTags'] : ['findAllVideoTags', variables],
    fetcher<FindAllVideoTagsQuery, FindAllVideoTagsQueryVariables>(
      FindAllVideoTagsDocument,
      variables
    ),
    options
  );
export const FindVideoLessonDocument = `
    query findVideoLesson($id: ItemId, $locale: SiteLocale) {
  videoLesson(filter: {id: {eq: $id}}) {
    subtitles(locale: $locale) {
      url
    }
    video {
      video {
        mp4Url
      }
    }
  }
}
    `;
export const useFindVideoLessonQuery = <TData = FindVideoLessonQuery, TError = unknown>(
  variables?: FindVideoLessonQueryVariables,
  options?: UseQueryOptions<FindVideoLessonQuery, TError, TData>
) =>
  useQuery<FindVideoLessonQuery, TError, TData>(
    variables === undefined ? ['findVideoLesson'] : ['findVideoLesson', variables],
    fetcher<FindVideoLessonQuery, FindVideoLessonQueryVariables>(
      FindVideoLessonDocument,
      variables
    ),
    options
  );
