import { useEffect, useRef, useState } from 'react';

import { ItemFormSidebarPanel, RenderItemFormSidebarPanelCtx } from 'datocms-plugin-sdk';
import { Canvas, Section, Spinner } from 'datocms-react-ui';

import { SiteLocale, useFindVideoLessonQuery } from '../../services/dato/graphql/generated';
import { appendDevPrefix } from '../../utils/appendDevPrefix';

export const AVAILABLE_ITEM_TYPES = ['Video Lesson'];
export const DEFINITION: ItemFormSidebarPanel = {
  label: appendDevPrefix('Subtitles Preview'),
  id: 'subtitles-preview',
  placement: ['after', 'info'],
  rank: 3,
};

type Props = {
  ctx: RenderItemFormSidebarPanelCtx;
};

const ORIGINAL_VIDEO_WIDTH = 1080;
const ORIGINAL_VIDEO_HEIGHT = 1920;
// available width on the sidebar
const VIDEO_WIDTH = 224;
const VIDEO_HEIGHT = VIDEO_WIDTH * (ORIGINAL_VIDEO_HEIGHT / ORIGINAL_VIDEO_WIDTH);

export const Component = ({ ctx }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [video, setVideo] = useState<string>();
  const [subtitles, setSubtitles] = useState<string>();
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const { data, isLoading } = useFindVideoLessonQuery({
    id: ctx.item?.id,
    locale: ctx.locale as SiteLocale,
  });

  useEffect(() => {
    if (data?.videoLesson?.video?.video?.mp4Url) {
      setVideo(data.videoLesson.video.video.mp4Url);
    }
    if (data?.videoLesson?.subtitles) {
      setSubtitles(data.videoLesson.subtitles.url);
    }
  }, [data]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [ctx.locale, videoRef]);

  return (
    <Canvas ctx={ctx}>
      {ctx.item && !isLoading && !video && `⚠️ Missing video`}
      {ctx.item && !isLoading && !subtitles && `⚠️ Missing ${ctx.locale} subtitles`}
      {ctx.item && video && subtitles ? (
        <>
          <video
            ref={videoRef}
            controls
            crossOrigin="anonymous"
            src={video}
            width={VIDEO_WIDTH}
            height={VIDEO_HEIGHT}
          >
            <track default kind="captions" srcLang={ctx.locale} src={subtitles} />
          </video>
          <Section
            title="🎬 💬 Data"
            collapsible={{
              isOpen: isInfoOpen,
              onToggle: () => setIsInfoOpen((v) => !v),
            }}
          >
            <ul>
              <li>
                <a href={video} target="_blank" rel="noreferrer">
                  Video
                </a>
              </li>
              <li>
                <a href={subtitles} target="_blank" rel="noreferrer">
                  Subtitles
                </a>
              </li>
            </ul>
          </Section>
        </>
      ) : (
        <>{ctx.item ? <Spinner size={20} /> : 'You first need to save this record'}</>
      )}
    </Canvas>
  );
};
