import { useState } from 'react';

import { Item, ItemFormSidebarPanel, RenderItemFormSidebarPanelCtx } from 'datocms-plugin-sdk';
import { Button, Canvas, Section } from 'datocms-react-ui';

import { appendDevPrefix } from '../../utils/appendDevPrefix';

const ALAN_UNIVERSAL_LINK_PREFIX = 'https://link.alan.com';

const links: Record<string, (item: Item) => string> = {
  'Prevention Article': (item) =>
    `${ALAN_UNIVERSAL_LINK_PREFIX}/prevention_article?article_id=${item.id}`,
  'Health program': (item) =>
    `${ALAN_UNIVERSAL_LINK_PREFIX}/health_program?program=${
      (item.attributes.slug as string).split('/')[0] // A given program can have multiple variants but the deeplink only works with the program slug prefix
    }`,
  'Standalone breathing exercise': (item) =>
    `${ALAN_UNIVERSAL_LINK_PREFIX}/breathing_exercise?id=${item.id}`,
  'Standalone meditation exercise': (item) =>
    `${ALAN_UNIVERSAL_LINK_PREFIX}/meditation_exercise?id=${item.id}`,
  'Standalone video': (item) => `${ALAN_UNIVERSAL_LINK_PREFIX}/video?video_id=${item.id}`,
  Space: (item) => `${ALAN_UNIVERSAL_LINK_PREFIX}/space?space_id=${item.id}`,
};

export const AVAILABLE_ITEM_TYPES = Object.keys(links);
export const DEFINITION: ItemFormSidebarPanel = {
  label: appendDevPrefix('Alan Universal Link'),
  id: 'alan-universal-link',
  placement: ['after', 'info'],
  rank: 2,
};

type Props = {
  ctx: RenderItemFormSidebarPanelCtx;
};

export const Component = ({ ctx }: Props) => {
  const [isInfoOpen, setIsInfoOpen] = useState(false);
  const handleClick = async () => {
    if (ctx.item?.meta.status !== 'published') {
      ctx.alert('Please, make sure to publish this item first');
      return;
    }
    if (!ctx.item?.meta.is_valid) {
      ctx.alert('Please, make sure to this item is valid');
      return;
    }

    const urlGenerator = links[ctx.itemType.attributes.name];
    if (!urlGenerator) {
      ctx.alert('Please, make sure to this item can handle universal link');
      return;
    }

    const url = urlGenerator(ctx.item!);
    try {
      await navigator.clipboard.writeText(url);
      ctx.notice('Link copied to your clipboard');
    } catch {
      ctx.alert('Could not copy link');
    }
  };

  return (
    <Canvas ctx={ctx}>
      {ctx.item ? (
        <>
          <Button fullWidth buttonSize="xxs" buttonType="primary" onClick={handleClick}>
            Get universal link
          </Button>
          <Section
            title="ℹ️ About"
            collapsible={{
              isOpen: isInfoOpen,
              onToggle: () => setIsInfoOpen((v) => !v),
            }}
          >
            Copy link that can be used to link directly to this record in-app (e.g. from
            newsletters).
          </Section>
        </>
      ) : (
        'You first need to save this record'
      )}
    </Canvas>
  );
};
