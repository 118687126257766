import {
  useFindAllArticlesQuery,
  useFindAllBreakthroughsQuery,
  useFindAllBreathingExercisesQuery,
  useFindAllMeditationExercisesQuery,
  useFindAllVideoModulesQuery,
} from '../services/dato/graphql/generated';

export const usePublishing = () => {
  const {
    data: articlesData,
    isLoading: isArticlesLoading,
    isError: isArticlesError,
    error: articlesError,
  } = useFindAllArticlesQuery({});

  const {
    data: breakthroughsData,
    isLoading: isBreakthroughsLoading,
    isError: isBreakthroughsError,
    error: breakthroughsError,
  } = useFindAllBreakthroughsQuery({});

  const {
    data: videoModulesData,
    isLoading: isVideoModulesLoading,
    isError: isVideoModulesError,
    error: videoModulesError,
  } = useFindAllVideoModulesQuery({});

  const {
    data: breathingExercisesData,
    isLoading: isBreathingExercisesLoading,
    isError: isBreathingExercisesError,
    error: breathingExercisesError,
  } = useFindAllBreathingExercisesQuery({});

  const {
    data: meditationExercisesData,
    isLoading: isMeditationExercisesLoading,
    isError: isMeditationExercisesError,
    error: meditationExercisesError,
  } = useFindAllMeditationExercisesQuery({});

  const articles = {
    id: '1286270',
    name: 'Articles',
    records: articlesData?.allArticles,
  };
  const breakthroughs = {
    id: '1286282',
    name: 'Breakthroughs',
    records: breakthroughsData?.allBreakthroughs,
  };
  const videoModules = {
    id: '1316342',
    name: 'Video Modules',
    records: videoModulesData?.allVideoModules,
  };
  const breathingExercises = {
    name: 'Breathing Exercises',
    id: '1943378',
    records: breathingExercisesData?.allBreathingExercises,
  };
  const meditationExercises = {
    name: 'Meditation Exercises',
    id: '1996236',
    records: meditationExercisesData?.allMeditationExercises,
  };

  return {
    recordTypes: [articles, breakthroughs, videoModules, breathingExercises, meditationExercises],
    isLoading:
      isArticlesLoading ||
      isBreakthroughsLoading ||
      isVideoModulesLoading ||
      isBreathingExercisesLoading ||
      isMeditationExercisesLoading,
    isError:
      isArticlesError ||
      isBreakthroughsError ||
      isVideoModulesError ||
      isBreathingExercisesError ||
      isMeditationExercisesError,
    errors: [
      articlesError,
      breakthroughsError,
      videoModulesError,
      breathingExercisesError,
      meditationExercisesError,
    ],
  };
};
