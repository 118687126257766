const NAME_TO_RECORD_TYPE_ID: Record<string, number> = {
  alanMindLanguages: 1286276,
  alanMindProfessions: 1286275,
  alanMindSpecialties: 1286274,
  alanMindTherapists: 1286273,
  articles: 1286270,
  articleAuthors: 1286289,
  breakthroughs: 1286282,
  breakthroughDefinitions: 1286283,
  breathingExercises: 1943378,
  dailyJournalMoodQuestions: 1286281,
  dailyJournalQuestionsLists: 1286280,
  journeys: 1286271,
  journeyAudioSteps: 1286269,
  journeyQuestionSteps: 1286267,
  journeySessionDescriptions: 1286277,
  journeySessionNames: 1286278,
  journeySessionNexts: 1286295,
  journeySessionRecaps: 1286272,
  journeyTransitionSteps: 1286268,
  onboardingCategories: 1286299,
  onboardingCategoryScoreGrids: 1320162,
  onboardingLandingScreens: 1286286,
  onboardingMobileFlows: 1286297,
  onboardingQuestionScreens: 1286284,
  onboardingTransitionScreens: 1286287,
  quotes: 1286288,
  videoExperts: 1316340,
  videoLessons: 1316341,
  videoModules: 1316342,
  videoTags: 1339434,
};

export const getRecordTypeIdFromName = (
  name: string,
  stripAllPrefix: boolean
): {
  recordTypeName: string;
  recordTypeId?: number;
} => {
  const computedName = stripAllPrefix
    ? name.slice(3).charAt(0).toLowerCase() + name.slice(4)
    : name;
  return {
    recordTypeName: computedName,
    recordTypeId: NAME_TO_RECORD_TYPE_ID[computedName],
  };
};
