import { useState } from 'react';

import { Item, ItemFormSidebarPanel, RenderItemFormSidebarPanelCtx } from 'datocms-plugin-sdk';
import { Button, Canvas, Section } from 'datocms-react-ui';

import { appendDevPrefix } from '../../utils/appendDevPrefix';

export const MIND_UNIVERSAL_LINK_LANG_PLACEHOLDER = '#{lang}';

const MIND_UNIVERSAL_LINK_PREFIX = `https://mind.alan.com/${MIND_UNIVERSAL_LINK_LANG_PLACEHOLDER}`;

const links: Record<string, (item: Item) => string> = {
  Article: (item) => `${MIND_UNIVERSAL_LINK_PREFIX}/app/articles/${item.id}`,
  Breakthrough: (item) => `${MIND_UNIVERSAL_LINK_PREFIX}/app/breakthroughs/${item.id}`,
  'Video Module': (item) => `${MIND_UNIVERSAL_LINK_PREFIX}/app/masterclasses/${item.id}`,
  'Breathing Exercise': (item) =>
    `${MIND_UNIVERSAL_LINK_PREFIX}/app/breathing-exercises/${item.attributes.slug}`,
  'Meditation Exercise': (item) =>
    `${MIND_UNIVERSAL_LINK_PREFIX}/app/meditation-exercises/${item.attributes.slug}`,
  Journey: (item) => {
    switch (item.attributes.journey_type) {
      case 'guide':
        return `${MIND_UNIVERSAL_LINK_PREFIX}/app/guided-journals/${item.attributes.legacy_id}`;

      case 'checkin':
        return `${MIND_UNIVERSAL_LINK_PREFIX}/app/checkins/${item.attributes.legacy_id}`;

      default:
        return `${MIND_UNIVERSAL_LINK_PREFIX}/app`;
    }
  },
};

export const AVAILABLE_ITEM_TYPES = Object.keys(links);
export const DEFINITION: ItemFormSidebarPanel = {
  label: appendDevPrefix('Mind Universal Link'),
  id: 'mind-universal-link',
  placement: ['after', 'info'],
  rank: 2,
};

type Props = {
  ctx: RenderItemFormSidebarPanelCtx;
};

export const Component = ({ ctx }: Props) => {
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const handleClick = async () => {
    if (ctx.item) {
      const urlGenerator = links[ctx.itemType.attributes.name];
      if (!urlGenerator) {
        ctx.alert('Please, make sure to this item can handle universal link');
        return;
      }
      const url = urlGenerator(ctx.item!);
      // gather langs in wich the content is ready
      // this relies on the `is_locale_ready` attribute we have on:
      // - Article
      // - Breakthrough
      // - Video Module
      // - Breathing Exercise
      // - Meditation Exercise
      // - Journey
      // So this code is very sensitive to it!
      const itemLocales = ctx.item.attributes.is_locale_ready as
        | Record<string, boolean>
        | undefined;
      if (!itemLocales) {
        ctx.alert(
          'This item is missing the "Is Locale Ready" attribute so this plugin will not work! Please ping mind engineers.'
        );
      } else {
        const locales = Object.entries<boolean>(itemLocales)
          // only keep langs that are locale ready
          .filter(([k, v]) => v)
          // then only keep the lang 2 letter country code
          .map((e) => e[0]);
        await ctx.openModal({
          id: 'universalLinkLangModal',
          title: 'Choose the language of the universal link',
          width: 'l',
          parameters: { url, locales },
        });
      }
    }
  };

  return (
    <Canvas ctx={ctx}>
      {ctx.item ? (
        <>
          <Button fullWidth buttonSize="xxs" buttonType="primary" onClick={handleClick}>
            Get universal link
          </Button>
          <Section
            title="ℹ️ About"
            collapsible={{
              isOpen: isInfoOpen,
              onToggle: () => setIsInfoOpen((v) => !v),
            }}
          >
            Copy link that can be used to link directly to this record in-app (e.g. from
            newsletters).
          </Section>
        </>
      ) : (
        'You first need to save this record'
      )}
    </Canvas>
  );
};
