import { useState } from 'react';

import { Item, ItemFormSidebarPanel, RenderItemFormSidebarPanelCtx } from 'datocms-plugin-sdk';
import { Button, Canvas, Section, Spinner } from 'datocms-react-ui';
import QRCode from 'qrcode.react';

import { appendDevPrefix } from '../../utils/appendDevPrefix';

const links: Record<string, (item: Item) => string> = {
  Article: (item) => `jourapp://articles/${item.id}?preview=true`,
  Breakthrough: (item) => `jourapp://breakthroughs/${item.id}?preview=true`,
  'Breathing Exercise': (item) =>
    `jourapp://breathing-exercises/${item.attributes.slug}?preview=true`,
  'Meditation Exercise': (item) =>
    `jourapp://meditation-exercises/${item.attributes.slug}?preview=true`,
};

export const AVAILABLE_ITEM_TYPES = Object.keys(links);
export const DEFINITION: ItemFormSidebarPanel = {
  label: appendDevPrefix('In-app Preview'),
  id: 'in-app-preview',
  placement: ['after', 'info'],
  rank: 1,
};

type Props = {
  ctx: RenderItemFormSidebarPanelCtx;
};

export const Component = ({ ctx }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const url = links[ctx.itemType.attributes.name](ctx.item!);

  const handleClick = async () => {
    setIsLoading(true);

    try {
      await navigator.clipboard.writeText(url);
      ctx.notice('Link copied to your clipboard');
    } catch {
      ctx.alert('Could not copy link');
    } finally {
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  };

  return (
    <Canvas ctx={ctx}>
      {ctx.item ? (
        <>
          <div>
            <QRCode value={url} />,
          </div>
          <div>
            <Button
              fullWidth
              buttonSize="xxs"
              buttonType="primary"
              onClick={handleClick}
              disabled={isLoading}
            >
              {isLoading ? <Spinner size={20} /> : 'Or copy deep-link'}
            </Button>
          </div>
          <Section
            title="ℹ️ About"
            collapsible={{
              isOpen: isInfoOpen,
              onToggle: () => setIsInfoOpen((v) => !v),
            }}
          >
            Flash this QRCode from your phone (you need to have "Alan Mind" acceptance/staging app
            installed). to preview this record-in app. Any saved change (even if not published) will
            be viewable.
          </Section>
        </>
      ) : (
        'You first need to save this record'
      )}
    </Canvas>
  );
};
