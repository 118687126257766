import { RenderModalCtx } from 'datocms-plugin-sdk';
import { Button, Canvas } from 'datocms-react-ui';

import { MIND_UNIVERSAL_LINK_LANG_PLACEHOLDER } from '../../sidebar/MindUniversalLink';

type CTXParams = { url: string; locales: string[] };

export const UniversalLinkLangModal = ({ ctx }: { ctx: RenderModalCtx }) => {
  const { url, locales } = ctx.parameters as CTXParams;
  const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
  const handleClick = async (locale: string) => {
    const localizedUrl = url.replace(MIND_UNIVERSAL_LINK_LANG_PLACEHOLDER, locale);
    try {
      await navigator.clipboard.writeText(localizedUrl);
      ctx.notice('Link copied to your clipboard');
    } catch {
      ctx.alert('Could not copy link');
    }
  };
  return (
    <Canvas ctx={ctx}>
      <div style={{ display: 'flex', flexDirection: 'column', gap: 16 }}>
        {locales.length === 0 && (
          <>
            <h3>It seems this item has no locale ready language!</h3>
            <p>
              Before being able to generate an universal link, please ensure the item has its
              content ready for the given language.
            </p>
          </>
        )}
        {locales.map((locale) => {
          return (
            <Button buttonSize="m" buttonType="primary" onClick={() => handleClick(locale)}>
              {languageNames.of(locale)}
            </Button>
          );
        })}
      </div>
    </Canvas>
  );
};
