import React from 'react';

import s from '../../global.module.css';

export const H1: React.FC = ({ children }) => {
  return <h1 className={s['appH1']}>{children}</h1>;
};

export const H2: React.FC = ({ children }) => {
  return <h2 className={s['appH2']}>{children}</h2>;
};

export const H3: React.FC = ({ children }) => {
  return <h3 className={s['appH3']}>{children}</h3>;
};
